<template>
  <div class="login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
      autocomplete="off"
      size="big"
      class="login-form"
    >
      <el-form-item style="text-align:center">
        <div style="font-size:30px;font-weight:bold" />
      </el-form-item>
      <h3 class="title">{{ name }}</h3>
      <el-form-item prop="userName">
        <el-input
          ref="username"
          v-model="loginForm.account"
          placeholder="请输入用户名"
          name="account"
          type="text"
          tabindex="1"
          autocomplete="on"
        >
          <i
            slot="prefix"
            class="el-icon-user-solid"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          ref="password"
          v-model="loginForm.password"
          placeholder="请输入登录密码"
          name="password"
          type="password"
          tabindex="2"
          autocomplete="off"
        >
          <i
            slot="prefix"
            class="el-icon-lock"
          />
        </el-input>
      </el-form-item>
      <!-- <el-form-item
        prop="verifyCode"
        class="verifyCodeBox"
      >
        <el-input
          ref="verifyCode"
          v-model="loginForm.verifyCode"
          placeholder="请输入验证码"
          name="verifyCode"
          type="text"
          tabindex="3"
          style="width:65%;"
          autocomplete="off"
        />
        <el-image
          :src="verifyCodeImg"
          @click="refreshCode"
        />
      </el-form-item> -->
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0 0 25px 0;"> 记住我 </el-checkbox> -->

      <el-form-item style="width:100%;">
        <el-button
          type="primary"
          style="width:100%;"
          size="big"
          @click.native.prevent="login"
        >登 录</el-button>
      </el-form-item>
    </el-form>

    <div id="el-login-footer">
      <span>{{ (new Date()).getFullYear() }}</span>
      <span> ⋅ </span>
      <a
        href="http://www.baidu.com/"
        target="_blank"
      >版权所有 © 陕西宛初云网络科技有限公司</a>
    </div>
  </div>
</template>

<script>
const defaultSetting = require('@/setting.js')
export default {
    data() {
        return {
            name: defaultSetting.name,
            loginForm: {
                account: '',
                password: ''
            },
            loginRules: {
                account: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入用户名！'
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入登录密码！'
                    }
                ]
                // verifyCode: [
                //     {
                //         required: true,
                //         trigger: 'blur',
                //         message: '请输入验证码！'
                //     }
                // ]
            },
            verifyCodeImg: ''
        }
    },
    methods: {
        async login() {
            let isValid = false
            this.$refs.loginForm.validate(async(valid) => {
                isValid = valid
            })
            if (isValid) {
                const res = await this.$store.dispatch('user/goLogin', this.loginForm)
                if (res.code === 0) {
                    console.log(this.$router.query)
                    const redirect = this.$router.query ? this.$router.query.redirect : ''
                    this.$router.push({ path: redirect || '/' })
                } else {
                    this.$message.error(res.message)
                }
            }
        },
        refreshCode() { }
    }
}
</script>

<style lang='scss' >
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: url("../../assets/images/login-bg.jpg") no-repeat;
    background-size: 100% 100%;
    .title {
        margin: 0 auto 30px auto;
        text-align: center;
        font-size: 24px;
        color: #707070;
    }

    .login-form {
        border-radius: 6px;
        background: #ffffff;
        width: 385px;
        padding: 20px;
    }
    .login-form .el-input {
        height: 38px;
    }
    .login-form .input-icon {
        height: 39px;
        width: 14px;
        margin-left: 2px;
    }
    .login-tip {
        font-size: 13px;
        text-align: center;
        color: #bfbfbf;
    }
    .login-code {
        width: 33%;
        display: inline-block;
        height: 38px;
    }
    #el-login-footer {
        height: 40px;
        line-height: 40px;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: Arial, serif;
        font-size: 12px;
        letter-spacing: 1px;
    }
    .verifyCodeBox .el-form-item__content {
        display: flex;
        justify-content: space-between;
    }
    .el-image {
        width: 35%;
    }
}
</style>
